import companyName from "../store/mobxStore/companyName";
const COMPANY_NAME = companyName.companyName ?? "Company Name";
const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";

// const API_URL = "http://162.19.146.132:2008"; //demo
// const API_URL = "http://139.59.164.21:5000"; //???
// mnn

// const API_URL = "http://192.168.21.19:5001"; //local
const API_URL = "https://stagingadmin.carbonwar.io"; //staging
// const API_URL = "https://admin.carbonwar.io"; //live

const BASE_URL = `${API_URL}/api/web/`;
const IMG_URL = `${API_URL}/api/uploads/images/`;
const LEAD_URL = `${API_URL}/api/web/`;
const BASE = `${API_URL}/api/web/`;

export {
  COMPANY_NAME,
  BASE_URL,
  IMG_URL,
  API_KEY,
  BASE,
  DEFAULT_KEY,
  LEAD_URL,
};
